import React from 'react';
import type {
  ProductCarouselProduct,
  ProductCarouselProps,
} from '@noths/polaris-client-ribbons-design-system';
import { ProductCarousel } from '@noths/polaris-client-ribbons-design-system';
import type { CurrencyCode } from '@noths/polaris-dev-ts-types';

import { SectionContainer } from 'src/components/molecules/SectionContainer/SectionContainer';
import type { CatalogServiceProductData } from 'src/services/catalog/types';
import { trackCarouselNavigation } from 'src/tracking/common/carousel';
import { trackProductClick, trackProductImpression } from 'src/tracking/common/products';
import { transformProductModelToProductForTracking } from 'src/tracking/common/productTransformer';
import type { TrackingContext } from 'src/types/TrackingContext';
import * as styles from './ProductCarouselSection.style';
import { transformProductModelToProductCarouselProduct } from './transformer';

export interface ProductCarouselSectionProps extends Omit<ProductCarouselProps, 'products'> {
  currency: CurrencyCode;
  pageType: string;
  products: CatalogServiceProductData[];
  trackingPosition?: number;
  trackingTitle: string;
}

export const ProductCarouselSection = ({
  currency,
  pageType,
  products,
  trackingPosition,
  trackingTitle,
  ...restOfProps
}: ProductCarouselSectionProps) => {
  const trackingContext: TrackingContext = {
    trackingPosition,
    trackingTitle,
    trackingContentType: 'Product carousel',
  };

  return (
    <SectionContainer columnsXL={10} maxWidth="max" wrapperStyles={styles.wrapper}>
      <ProductCarousel
        onNavigation={(swiped, control) => {
          const trackingDirection = control === 'next' ? 'next' : 'previous';

          trackCarouselNavigation(swiped, trackingDirection, {
            ...trackingContext,
            numberOfItems: products.length,
          });
        }}
        onProductClick={(e: React.MouseEvent<HTMLAnchorElement>, { index }: { index: number }) => {
          if (products.length > 0) {
            trackProductClick(e, {
              ...trackingContext,
              pageType,
              product: transformProductModelToProductForTracking(products[index]),
              productIndex: index,
              numberOfItems: products.length,
            });
          }
        }}
        onVisible={() => {
          if (products.length > 0) {
            trackProductImpression({
              ...trackingContext,
              pageType,
              products: products.map(transformProductModelToProductForTracking),
            });
          }
        }}
        products={products.map((product) =>
          transformProductModelToProductCarouselProduct(product, { currency }),
        )}
        {...restOfProps}
      />
    </SectionContainer>
  );
};

export type { ProductCarouselProps, ProductCarouselProduct };
