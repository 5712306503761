import type { ProductCarouselProduct } from '@noths/polaris-client-ribbons-design-system';
import type { CurrencyCode } from '@noths/polaris-dev-ts-types';

import type { CatalogServiceProductData } from 'src/services/catalog/types';

export const transformProductModelToProductCarouselProduct = (
  {
    images,
    isNew,
    links,
    onSale,
    preSalePrices,
    prices,
    purchasable,
    salePercentage,
    title,
  }: CatalogServiceProductData,
  { currency }: { currency: CurrencyCode },
): ProductCarouselProduct => ({
  title,
  src: images[0]?.href || '',
  href: links[0]?.href || '',
  alt: '',
  isNew,
  onSale,
  isPurchasable: purchasable,
  price: prices?.find((prices) => prices.currency === currency),
  preSalePrice: preSalePrices?.find((price) => price.currency === currency),
  salePercentage: salePercentage ?? undefined,
});
