import type { CatalogServiceProductData } from 'src/services/catalog/types';
import type { TrackingProductData } from 'src/tracking/common/products';

// The price of a product is always pushed to dataLayer in GBP
export const transformProductModelToProductForTracking = ({
  code,
  freeDomesticDelivery,
  isNew,
  onSale,
  partner,
  price,
  purchasable,
  salePercentage,
  title,
}: CatalogServiceProductData): TrackingProductData => ({
  code,
  freeDomesticDelivery,
  isNew,
  onSale,
  salePercentage,
  partner,
  price,
  purchasable,
  title,
});
